import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Container from '@common/src/components/UI/Container';

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

import SectionWrapper, {
  Section,
  Content,
  Illustration
} from './customer.style';

const Customer = () => {
  const Data = useStaticQuery(graphql`
    {
      contentfulTentangKami(title: { eq: "Bukan Hanya Kecanggihan Teknologi, Melainkan juga Pakar Ahli" }) {
        image {
          gatsbyImageData
        }
        title
        description {
          raw
        }
      }
    }
  `);
  const img = Data.contentfulTentangKami.image.gatsbyImageData;
  const title = Data.contentfulTentangKami.title;
  const description = Data.contentfulTentangKami.description;

  const renderOptions = {
    renderNode: {
        [BLOCKS.HEADING_1]: (node, children) => {
            if(children[0] === "") return <></>
            return <h1 className="text-xl md:text-3xl font-bold mb-2 text-dark">{children}</h1>
        },
        [BLOCKS.HEADING_2]: (node, children) => {
            if(children[0] === "") return <></>
            return <h2 className="text-lg md:text-2xl font-bold mb-2 text-dark">{children}</h2>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            if(children[0] === "") return <></>
            return <h3 className="text-base md:text-xl font-bold mb-2 text-dark">{children}</h3>
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
            if(children[0] === "") return <></>
            return <p className="text-sm md:text-base mb-4 text-dark text-opacity-80">{children}</p>
        },
        [BLOCKS.HR]: () => {return <hr/>},
        [INLINES.HYPERLINK]: (node, children) => {
            return (
              <a className="text-blue-700 hover:underline" href={node.data.uri}>
                {node.content[0].value}
              </a>
            )
        },
    },
    renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>,
        [MARKS.ITALIC]: text => <i>{text}</i>,
    },
  }
  
  return (
    <SectionWrapper id="pakar-ahli-perusahaan">
      <Container>
        <Section>
          <Illustration>
            <Fade left delay={100}>
              <GatsbyImage image={getImage(img)}  alt=''/>
            </Fade>
          </Illustration>
          <Content>
            <Fade right delay={100}>
              <h2>{title}</h2>
              {description && (
                <div className='markdown'>
                  {renderRichText(description, renderOptions)}
                </div>
              )}
            </Fade>
          </Content>
        </Section>
      </Container>
    </SectionWrapper>
  );
};

export default Customer;
