import React from "react";
import Fade from "react-reveal/Fade";
import JoinTrailArea, { ContentWrapper } from "./joinTrail.style";
import { useStaticQuery, graphql } from 'gatsby';

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'

const JoinTrail = () => {
  const Data = useStaticQuery(graphql`
    {
      contentfulTentangKami(title: { eq: "Pemeriksaan Genetik dengan Tingkat Akurasi" }) {
        title
        description {
          raw
        }
      }
    }
  `);
  const title = Data.contentfulTentangKami.title;
  const description = Data.contentfulTentangKami.description;

  const renderOptions = {
    renderNode: {
        [BLOCKS.HEADING_3]: (node, children) => {
            if(children[0] === "") return <></>
            return <h3 className="text-base md:text-xl font-bold mb-2 text-dark">{children}</h3>
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
            if(children[0] === "") return <></>
            return <p className="text-sm md:text-base mb-4 text-dark text-opacity-80">{children}</p>
        },
        [BLOCKS.HR]: () => {return <hr/>},
    },
    renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>,
    },
  }

  return (
    <JoinTrailArea id="akurasi-perusahaan">
      <ContentWrapper>
        <Fade up delay={100}>
          <h2>{title}</h2>
        </Fade>
        <Fade up delay={200}>
          {description && (
            <div className='markdown'>
              {renderRichText(description, renderOptions)}
            </div>
          )}
        </Fade>
      </ContentWrapper>
    </JoinTrailArea>
  );
};

export default JoinTrail;
