import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "@common/src/theme/agencyModern";
import { ResetCSS } from "@common/src/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "../components/styling-wrappers/agencyModern.style";
import { DrawerProvider } from "@common/src/contexts/DrawerContext";
import Navbar from "../components/public-components/Navbar";
import Teknologi from "../components/tentang-kami-components/Teknologi";
import Akurasi from "../components/tentang-kami-components/Akurasi";
import Tentang from "../components/tentang-kami-components/Tentang";
import PakarAhli from "../components/tentang-kami-components/PakarAhli";
import Keamanan from "../components/tentang-kami-components/Keamanan";
import Jaminan from "../components/tentang-kami-components/Jaminan";
import Footer from "../components/public-components/Footer";
import Seo from "../components/seo";
import PopupImage from "../components/PopupImage";
import ButtonContact from "../components/index-components/ButtonContact";
import Layout from "../components/layout";

const tentangKamiPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Seo
        title="Tentang Kami"
        description="PT Bifarma Adiluhung merupakan anak perusahaan Kalbe Farma yang bergerak di bidang bioteknologi terkemuka. Ini memiliki tujuan untuk menyediakan obat generik serta serta mengembangkan biofarmasetika inovatif dan pemeriksaan kesehatan yang terbukti secara klinis dan berkualitas."
      />    
      <ResetCSS />
      <GlobalStyle />
      <Layout>
        <PopupImage/>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar origin="Tentang Kami" />
            </DrawerProvider>
          </Sticky>
          <Tentang />
          {/* <Teknologi /> */}
          <Akurasi />
          <PakarAhli />
          <Keamanan />
          <Jaminan />
          <Footer />
          <ButtonContact/>
        </ContentWrapper>
      </Layout>
    </ThemeProvider>
  );
};

export default tentangKamiPage;
