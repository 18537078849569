import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'

import Container from '@common/src/components/UI/Container';

import SectionWrapper, {
  Section,
  Content,
  Illustration
} from './customer.style';

const Customer = () => {
  const Data = useStaticQuery(graphql`
    {
      contentfulTentangKami(title: { eq: "Tentang Kami" }) {
        image {
          gatsbyImageData
        }
        title
        description {
          raw
        }
      }
    }
  `);
  const img = Data.contentfulTentangKami.image.gatsbyImageData;
  const title = Data.contentfulTentangKami.title;
  const description = Data.contentfulTentangKami.description;

  console.log('Raw Description:', description); 

  const renderOptions = {
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => {
            if(children[0] === "") return <></>
            return <h2 className="text-lg md:text-2xl font-bold mb-2 text-dark">{children}</h2>
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
            if(children[0] === "") return <></>
            return <p className="text-sm md:text-base mb-4 text-dark text-opacity-80">{children}</p>
        },
        [BLOCKS.HR]: () => {return <hr/>},
    },
    renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>,
    },
  }

  return (
    <SectionWrapper id="profil-perusahaan">
      <Container>
        <Section>
          <Illustration>
            <Fade left delay={100}>
              <GatsbyImage image={getImage(img)} alt='' />
            </Fade>
          </Illustration>
          <Content>
            <Fade right delay={100}>
              <h2>{title}</h2>
              {description && (
                <div className='markdown'>
                  {renderRichText(description, renderOptions)}
                </div>
              )}
            </Fade>
          </Content>
        </Section>
      </Container>
    </SectionWrapper>
  );
};

export default Customer;
