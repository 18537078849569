import styled from 'styled-components';
import { themeGet } from 'styled-system';

const TransactionsWrapper = styled.section`
  padding: 100px 0 75px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 50px;
  }
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 60px;
    color: ${themeGet('colors.genmeText')};
    @media only screen and (min-width: 1024px) and (max-width: 1366px) {
      font-size: 27px;
      line-height: 50px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 22px;
      line-height: 40px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 42px;
    }
  }
  p {
    font-size: 16px;
    line-height: 42px;
    color: ${themeGet('colors.genmeText')};
    text-align: justify;
    @media only screen and (min-width: 1024px) and (max-width: 1366px) {
      line-height: 32px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      line-height: 28px;
    }
    @media only screen and (max-width: 768px) {
      line-height: 32px;
    }
  }
  button {
    border-radius: 6px;
    background-image: -moz-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -webkit-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -ms-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    &:hover {
      box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
    }
    > span {
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  .colleft {
    width: 50%;
    @media (max-width: 1440px) {
      width: 50%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .colright {
    width: 50%;
    @media (max-width: 1440px) {
      width: 50%;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const FeatureSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .featureWrapper {
    width: 200px;
    @media (max-width: 599px) {
      max-width: 100%;
    }
    img {
      width: 125px;
      height: 125px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 426px) {
    justify-content: center;
    margin: 0 20%;
    .featureWrapper {
      width: 125px;
      @media (max-width: 599px) {
        max-width: 100%;
      }
      img {
        width: 125px;
        height: 125px;
        margin-bottom: 20px;
      }
      p {
        text-align: center;
        font-size: 14px !important;
      }
    }
  }
`;
export { TransactionsWrapper, FeatureSection };
