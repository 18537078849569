import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Box from '@common/src/components/Box';
import Fade from 'react-reveal/Fade';
import Text from '@common/src/components/Text';
import Heading from '@common/src/components/Heading';
import FeatureBlock from '@common/src/components/FeatureBlock';
import Container from '@common/src/components/UI/Container';
import { TransactionsWrapper, FeatureSection } from './transaction.style';

const TransactionsHistory = ({
  row,
  col,
  title,
  description,
  cardArea,
  featureTitleStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulTentangKami(
        sort: { fields: image___createdAt, order: ASC }
        limit: 2
      ) {
        nodes {
          title
          image {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const teknologi = Data.allContentfulTentangKami.nodes;

  return (
    <TransactionsWrapper id="pelayanan-perusahaan">
      <Container>
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: 'column' }}>
            {/* <Text content="How Transactions Work" {...sectionSubTitle} /> */}
            <FeatureBlock
              title={
                <Heading
                  as="h2"
                  content="Pelayanan Terbaik Kepada Pelanggan Terbaik "
                  {...title}
                />
              }
              description={
                <Text
                  content=" PT Bifarma Adiluhung (Kalbe Group) menggunakan teknologi terbaik yang ada saat ini dalam pemeriksaan genetik."
                  {...description}
                />
              }
            />
          </Box>
          <Box className="colright" {...col} {...cardArea}>
            <FeatureSection>
              {teknologi.map((item, index) => (
                <Fade right delay={100} key={`feature-${index}`}>
                  <div key={`feature-${index}`} className="featureWrapper">
                    <GatsbyImage
                      image={item.image.gatsbyImageData}
                      alt={item.title}
                    />
                    <Text content={item.title} {...featureTitleStyle} />
                  </div>
                </Fade>
              ))}
            </FeatureSection>
          </Box>
        </Box>
      </Container>
    </TransactionsWrapper>
  );
};

// Transactions style props
TransactionsHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object
};

// Transactions default style
TransactionsHistory.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // Transactions section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center'
  },
  // Transactions section title default style
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px']
  },
  // Transactions section description default style
  description: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '40px', '40px', '55px'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px']
  },
  sectionSubTitle: {
    as: 'span',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f'
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500'
  },
  featureTitleStyle: {
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['25px', '27px', '27px', '27px', '27px'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px'
  },
  // Transactions section description default style
  featureDescriptionStyle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '27px'
  }
};

export default TransactionsHistory;
