import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Container from '@common/src/components/UI/Container';
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { MARKS } from '@contentful/rich-text-types'

import SectionWrapper, {
  Section,
  Content,
  Illustration
} from './customer.style';

const Customer = () => {
  const Data = useStaticQuery(graphql`
    {
      contentfulTentangKami(title: { eq: "Keamanan dan Privasi Anda Terjamin" }) {
        image {
          gatsbyImageData
        }
        title
        description{
          raw
        }
      }
    }
  `);
  const img = Data.contentfulTentangKami.image.gatsbyImageData;
  const title = Data.contentfulTentangKami.title;
  const description = Data.contentfulTentangKami.description;

  const renderOptions = {
    renderNode: {
    },
    renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>,
        [MARKS.ITALIC]: text => <i>{text}</i>,
    },
  }

  return (
    <SectionWrapper id="privasi-perusahaan">
      <Container>
        <Section>
          <Content>
            <Fade left delay={100}>
            <h2>{title}</h2>
              {description && (
                <div className='markdown'>
                  {renderRichText(description, renderOptions)}
                </div>
              )}
            </Fade>
          </Content>
          <Illustration>
            <Fade right delay={100}>
              <GatsbyImage image={getImage(img)}  alt=''/>
            </Fade>
          </Illustration>
        </Section>
      </Container>
    </SectionWrapper>
  );
};

export default Customer;
