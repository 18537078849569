import styled from 'styled-components';
import { themeGet } from 'styled-system';

const JoinTrailArea = styled.div`
  width: 100%;
  padding: 75px 0;
  overflow: hidden;
  background-color: ${themeGet('colors.genmeLight')};
  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 45px 20px;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 460px;
  margin: 0 auto;
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: 72px;
    line-height: 60px;
    color: ${themeGet('colors.genmePrimary')};
    @media only screen and (min-width: 1024px) and (max-width: 1366px) {
      font-size: 56px;
      line-height: 50px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 52px;
      line-height: 40px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 54px;
      line-height: 42px;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 60px;
    color: ${themeGet('colors.genmeText')};
    @media only screen and (min-width: 1024px) and (max-width: 1366px) {
      font-size: 27px;
      line-height: 50px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 22px;
      line-height: 40px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 42px;
    }
  }
  img {
    display: inline-block;
    margin-bottom: 35px;
    @media only screen and (max-width: 667px) {
      margin-bottom: 27px;
      max-width: 50%;
    }
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 50px;
  @media only screen and (max-width: 1366px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 667px) {
    margin-top: 30px;
  }
  .reusecore__button {
    border-radius: 5px;
    text-transform: inherit;
    margin-right: 20px;
    &:first-child {
      &:hover {
        background-color: ${themeGet('colors.primaryHover', '#3C74FF')};
      }
    }
    &:last-child {
      margin-right: 0;
      color: ${themeGet('colors.headingColor', '#0F2137')};
    }
  }
`;

export default JoinTrailArea;
