import React from "react";
import Fade from "react-reveal/Fade";
import JoinTrailArea, { ContentWrapper } from "./joinTrail.style";
import { useStaticQuery, graphql } from 'gatsby';

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { MARKS } from '@contentful/rich-text-types'

const JoinTrail = () => {
  const Data = useStaticQuery(graphql`
    {
      contentfulTentangKami(title: { eq: "Jaminan Investasi Sekali Seumur Hidup" }) {
        title
        description{
          raw
        }
      }
    }
  `);
  const title = Data.contentfulTentangKami.title;
  const description = Data.contentfulTentangKami.description;

  const renderOptions = {
    renderNode: {
    },
    renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>,
        [MARKS.ITALIC]: text => <i>{text}</i>,
    },
  }
  
  return (
    <JoinTrailArea>
      <ContentWrapper>
        <Fade up delay={100}>
          <h2>{title}</h2>
        </Fade>
        <Fade up delay={200}>
          {description && (
            <div className='markdown'>
              {renderRichText(description, renderOptions)}
            </div>
          )}
        </Fade>
      </ContentWrapper>
    </JoinTrailArea>
  );
};

export default JoinTrail;
